



































































































import { Component, Prop } from "vue-property-decorator";
import { BaseForm } from "./BaseForm";

@Component
export default class LoginForm extends BaseForm {
  public labels: Record<string, string> = {
    email: "Email",
    password: "Password",
  };

  @Prop({ required: true })
  public signupUrl!: string;

  @Prop({ default: process.env.VUE_APP_RESET_URL })
  public forgotUrl!: string;

  public model: Record<string, any> = {
    email: null,
    password: null,
  };

  public async send() {
    this.$alert.clear();
    this.$alert.addAlert({
      type: "success",
      message: "Form submitted",
    });
    const data = {
      user: this.model.email,
    };
    this.$emit("done", { data });
  }
}
