










import { Component, Vue, Prop } from "vue-property-decorator";
import LoginForm from "./../components/forms/LoginForm.vue";

@Component({
  components: {
    LoginForm,
  },
  metaInfo: {
    title: "Login",
  },
})
export default class Login extends Vue {
  @Prop({ default: {} })
  public query!: Record<string, any>;

  public returnUrl: string = process.env.VUE_APP_HOME_URL;

  public action = process.env.VUE_APP_SIGNIN_ACTION;

  get signupUrl() {
    return this.$url(process.env.VUE_APP_SIGNUP_URL, this.query);
  }

  created() {
    if (this.query.continue !== undefined) {
      this.returnUrl = this.query.continue;
    }
    console.log("Return url", this.returnUrl);
    console.log(
      "Return url is internal? ",
      this.$isInternalUrl(this.returnUrl)
    );
  }

  onLoginDone({ data }: any) {
    this.$store.commit("SET_SIGNEDIN_STATUS", true);
    this.$store.commit("SET_CURRENT_USER", data.user);
    return this.$redirect(this.returnUrl, true);
  }
}
